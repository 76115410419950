import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
// eslint-disable-next-line import/no-cycle

export default function useCoproprietes() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const copropriete = ref([])
  const loader = ref(false)
  const coproprieteProcess = ref(false)
  const coproprieteSuccess = ref(false)

  const coproprietes = ref([])
  const errors = ref('')

  const getAllCoproprietes = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-coproprietes')
      if (response.data.success === true) {
        loader.value = false
        coproprietes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getCoproprieteById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-copropriete-by-id/${id}`)
      if (response.data.success === true) {
        copropriete.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const deleteCopropriete = async id => {
    try {
      errors.value = ''
      coproprieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.delete(`/delete-copropriete/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)

        coproprieteSuccess.value = true
        coproprieteProcess.value = false
        await getAllCoproprietes()
      }
    } catch (error) {
      coproprieteProcess.value = false
      coproprieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  const createCopropriete = async data => {
    try {
      errors.value = ''
      coproprieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-copropriete', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        coproprieteSuccess.value = true
        coproprieteProcess.value = false
        copropriete.value = response.data.data
        await getAllCoproprietes()
      }
    } catch (error) {
      coproprieteProcess.value = false
      coproprieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateCopropriete = async data => {
    try {
      errors.value = ''
      coproprieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-copropriete/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)

        coproprieteSuccess.value = true
        coproprieteProcess.value = false
        copropriete.value = response.data.data
        await getAllCoproprietes()
      }
    } catch (error) {
      coproprieteProcess.value = false
      coproprieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    errors,
    copropriete,
    coproprietes,
    coproprieteProcess,
    loader,
    coproprieteSuccess,
    getAllCoproprietes,
    getCoproprieteById,
    createCopropriete,
    updateCopropriete,
    deleteCopropriete,

  }
}
